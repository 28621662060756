import React from "react";
import {
  FaInstagram,
  FaSoundcloud,
  FaSpotify,
  FaApple,
  FaCheck,
} from "react-icons/fa";
import "./App.css";

function App() {
  const SongLogos = () => (
    <div className="song-logos">
      <FaCheck size={30} className="before-text-icon" />{" "}
      {/* Reduced size for the icon before text */}
      <span className="available-text">Available on</span>
      <a
        href="https://soundcloud.com/lavinworld/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "black" }}
      >
        <FaSoundcloud size={30} />
      </a>
      <a
        href="https://open.spotify.com/artist/0Y5liLnUhPDinzWEzJHhwG?si=-_Mr3bqvSoaMIL3HE89H5A"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "black" }}
      >
        <FaSpotify size={30} />
      </a>
      <a
        href="https://music.apple.com/tr/artist/lavin-world/1670685701"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "black" }}
      >
        <FaApple size={30} />
      </a>
    </div>
  );

  return (
    <div className="header-container">
      {/* <div className="shape1"></div> */}
      <div className="shape2"></div>
      <div className="shape3"></div>
      {/* <div className="shape4"></div> */}
      {/* <div className="shape5"></div> */}
      <nav className="nav">
        <div className="logo">Lavin World</div>
        <ul>
          <li class="menu-item-home">Official Lavin World Website</li>
          {/* <li class="menu-item-music">Music</li>
          <li class="menu-item-playlist">Playlist</li>
          <li class="menu-item-radio">Radio</li> */}
        </ul>
      </nav>
      <div className="artist-section">
        <img
          src={process.env.PUBLIC_URL + "/lavini.png"}
          alt="Artist"
          className="artist-image"
        />
        <div className="artist-info">
          <span>Hip-hop , Rap , Singer , Poet , Producer</span>
          <p className="artist-bio">
            Bio: Saeed Tarabi, professionally known as Lavin World, began his
            career in music, poetry writing, and song composition in 2020. To
            date, he has officially and unofficially released 20 songs.
            Throughout his journey, he has collaborated with various music
            composers. Moreover, some of these songs were mixed, mastered, and
            composed by Lavin himself. One of his most popular tracks is "Dari
            Miri," which stands out for its innovative fusion of multiple
            musical styles. Below, you can find links to Lavin's social media
            profiles.
          </p>

          <div className="social-icons">
            <a
              href="https://www.instagram.com/lavin.world/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "black" }}
            >
              <FaInstagram size={50} />
            </a>
            <a
              href="https://soundcloud.com/lavinworld/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "black" }}
            >
              <FaSoundcloud size={50} />
            </a>
            <a
              href="https://open.spotify.com/artist/0Y5liLnUhPDinzWEzJHhwG?si=-_Mr3bqvSoaMIL3HE89H5A"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "black" }}
            >
              <FaSpotify size={50} />
            </a>
            <a
              href="https://music.apple.com/tr/artist/lavin-world/1670685701"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "black" }}
            >
              <FaApple size={50} />
            </a>
          </div>
        </div>
      </div>
      <div className="playlist-container">
        <iframe
          width="100%"
          height="200"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1507432363&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
        ></iframe>
        <SongLogos />

        <div style={{ marginTop: "20px" }}>
          {/* <-- This is where the space is added */}
          <iframe
            width="100%"
            height="200"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1465161970&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
          ></iframe>
          <SongLogos />
        </div>
        <div style={{ marginTop: "20px" }}>
          {/* <-- This is where the space is added */}
          <iframe
            width="100%"
            height="200"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1441550884&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
          ></iframe>
          <SongLogos />
        </div>
        <div style={{ marginTop: "20px" }}>
          {/* <-- This is where the space is added */}
          <iframe
            width="100%"
            height="200"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1523135866&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
          ></iframe>
          <SongLogos />
        </div>
        <div style={{ marginTop: "20px" }}>
          {/* <-- This is where the space is added */}
          <iframe
            width="100%"
            height="200"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1441240162&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
          ></iframe>
          <SongLogos />
        </div>
        <div style={{ marginTop: "20px" }}>
          {/* <-- This is where the space is added */}
          <iframe
            width="100%"
            height="200"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1178197573&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
          ></iframe>
          <SongLogos />
        </div>
        <div style={{ marginTop: "20px" }}>
          {/* <-- This is where the space is added */}
          <iframe
            width="100%"
            height="200"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1164000067&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
          ></iframe>
          <SongLogos />
        </div>
        <div style={{ marginTop: "20px" }}>
          {/* <-- This is where the space is added */}
          <iframe
            width="100%"
            height="200"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1151790958&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
          ></iframe>
          <SongLogos />
        </div>
        <div style={{ marginTop: "20px" }}>
          {/* <-- This is where the space is added */}
          <iframe
            width="100%"
            height="200"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1146532558&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
          ></iframe>
          <SongLogos />
        </div>
        <div style={{ marginTop: "20px" }}>
          {/* <-- This is where the space is added */}
          <iframe
            width="100%"
            height="200"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1036398802&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
          ></iframe>
          <SongLogos />
        </div>
        <div style={{ marginTop: "20px" }}>
          {/* <-- This is where the space is added */}
          <iframe
            width="100%"
            height="200"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1032746818&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
          ></iframe>
          <SongLogos />
        </div>
        <div style={{ marginTop: "20px" }}>
          {/* <-- This is where the space is added */}
          <iframe
            width="100%"
            height="200"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1030797148&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
          ></iframe>
          <SongLogos />
        </div>
        <div style={{ marginTop: "20px" }}>
          {/* <-- This is where the space is added */}
          <iframe
            width="100%"
            height="200"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1027975426&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
          ></iframe>
          <SongLogos />
        </div>
        <div style={{ marginTop: "20px" }}>
          {/* <-- This is where the space is added */}
          <iframe
            width="100%"
            height="200"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/972433765&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
          ></iframe>
          <SongLogos />
        </div>
        <div style={{ marginTop: "20px" }}>
          {/* <-- This is where the space is added */}
          <iframe
            width="100%"
            height="200"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/952803859&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
          ></iframe>
          <SongLogos />
        </div>
        <div style={{ marginTop: "20px" }}>
          {/* <-- This is where the space is added */}
          <iframe
            width="100%"
            height="200"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/941316571&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
          ></iframe>
          <SongLogos />
        </div>
        <div style={{ marginTop: "20px" }}>
          {/* <-- This is where the space is added */}
          <iframe
            width="100%"
            height="200"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/820825303&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
          ></iframe>
          <SongLogos />
        </div>
      </div>
    </div>
  );
}

export default App;
